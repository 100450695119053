
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































































































































































































































































































































































.cats {
  border-collapse: collapse;

  td {
    padding: 0.5em 0;
    vertical-align: initial;
  }

  thead td {
    padding: 0.5em 1em;
    border-bottom: 1px solid $c-gray-lighter;
  }
}

.cats__label {
  padding: 1rem;
  text-transform: uppercase;
}

.cats__row {
  flex-wrap: nowrap;
}

.cats__radio__item {
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem;
}

.cats__checkbox__item {
  margin: 0 auto;
}

.submit {
  @include loading($c-orange);

  margin-left: auto;
}

.legacy-reset {
  margin-left: 2rem;
}

.tip__icon {
  @include mq(m) {
    margin-bottom: -2px;
  }
  fill: $c-main-primary;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  margin-left: 1em;
}

p.index_message {
  color: #55c2a7;
  margin-top: 0;
  line-height: 1em;
  max-width: 85%;
}

@media screen and (max-width: 767px) {
  p.index_message {
    max-width: 100%;
  }
}

.notification-html {
  float: left;
}

.clear {
  clear: both;
}
