
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




















.radio {
  display: flex;
}

input {
  @extend %visually-hidden;

  border: 0;

  &:checked + label::after {
    transform: scale(1);
  }
}

.label {
  position: relative;
  color: $c-black;
  font-size: 1.8rem;
  line-height: 1;

  &.no-label {
    width: 2rem;
    height: 2rem;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2rem;
    height: 2rem;
    border: 0.1rem solid $c-gray;
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    width: 1.2rem;
    height: 1.2rem;
    background: $c-orange;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.2s;
  }
}
