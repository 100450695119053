
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























.subtitle {
  font-size: 20px;
}
.infos {
  font-size: 20px;
  color: #e84e0f;
}
